<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

import {
  ref, reactive, onMounted, toRefs,
} from '@vue/composition-api'

import store from '@/store'
import * as PostService from '../../services/PostService'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },

  setup(props, context) {
    const stickyHeader = ref(true)
    const noCollapse = ref(false)
    const fields = ref([
      {
        key: 'id', stickyColumn: true, isRowHeader: true, variant: 'secondary',
      },
      {
        key: 'avatar', label: 'Avatar',
      },
      {
        key: 'full_name', label: 'Full Name', sortable: true,
      },
      {
        key: 'title', stickyColumn: true, variant: 'info', sortable: true,
      },
      'email',
      'city',
      'pub_date',
      {
        key: 'clicks', label: 'Clicks', sortable: true,
      },
      {
        key: 'rating', label: 'Rating', sortable: true,
      },
      'experience',
      {
        key: 'status', label: 'Status',
      },
    ])
    const status = ref([
      {
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }])
    let posts = ref([])
    const items = ref([])

    const settings = reactive({
      perPage: 5,
      pageOptions: [3, 5, 10, 999],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    })

    const localSearchQueryText = reactive({ title: '' })
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
    })

    const getPosts = () => {
      store.getters.isLoading = true

      PostService.getAllPostsWithSearch(localSearchQueryText.title)
        .then(response => {
          // console.log('RESPONSE====================================')
          // console.log('Response Data:')
          // console.log(response.data)
          // console.log('-----------------------------------------------')
          // console.log(`Status: ${response.data.status}`)
          // console.log(`Results: ${response.data.results}`)
          // console.log(`Data: \n ${response.data.data.data}`)
          posts = response.data.data.data

          posts.forEach((post, i) => {
            // console.log(post)
            items.value.push(
              {
                id: i,
                // eslint-disable-next-line global-require
                avatar: post.images[0],
                full_name: post.author ? post.author.username : '',
                title: `${post.title.slice(0, 30)}...`,
                email: 'kocrevy0@thetimes.co.uk',
                city: 'Krasnosilka',
                pub_date: post.pubDate,
                clicks: post.clicks,
                rating: post.rating,
                experience: post.categories,
                status: 2,
              },
            )
          })

          // console.log(response.data.data.data)
          // console.log('RESPONSE done====================================')
        })
        // .catch(error => {
        //   // console.log('\n\n Error====================================')
        //   // console.log(error)
        // })

      store.getters.isLoading = false
    }

    onMounted(getPosts)

    return {
      stickyHeader,
      noCollapse,
      fields,
      status,
      posts,
      items,

      ...toRefs(event),
      ...toRefs(settings),
      getPosts,
      localSearchQueryText,
    }
  },
}

</script>

<style lang="scss" scoped>
// @import '~@core/scss/base/bootstrap-extended/_variables';
</style>
